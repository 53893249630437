<template>
  <v-container fluid>
    <v-dialog v-model="dialogDeleteAllProducts" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline" primary-title>Удалить все товары поставщика?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="dialogDeleteAllProducts = false" outline>Отмена</v-btn>
          <v-btn color="error" @click="deleteAllProducts" :loading="deleteLoading">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <v-container v-if="supplier" fluid white>
        <v-layout row wrap>
          <v-flex xs8>
            <v-btn outline fab small :to="`/supplier/${supplier.id}`" class="mt-0 ml-0">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <span class="title">{{ supplier.title }}</span>
          </v-flex>
          <v-flex xs4 class="text-xs-right">
            <v-select
              v-model="parserType"
              :items="PARSER_TYPE_SELECT"
              box
              label="Способ обновления"
              :disabled="parserInProgress"
            >
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex xs12 class="text-xs-right">
            <template v-if="parserInProgress">
              <v-btn color="error" @click="stopParser" outline>Отменить загрузку</v-btn>
            </template>
            <template v-else>
              <v-btn color="info" @click="dialogDeleteAllProducts = true" outline>Очистить каталог</v-btn>
              <v-btn v-if="parserType === PARSER_TYPE.IKEA" color="info" @click="clearParser" outline
                >Удалить позиции, покинувшие ассортимент</v-btn
              >
              <input v-show="false" ref="file" type="file" @change="uploadFile($event)" :accept="fileExtensions" />
              <v-btn
                v-if="parserType === PARSER_TYPE.XLS || parserType === PARSER_TYPE.YML"
                @click="openFileDialog"
                color="info"
                :loading="loading"
                >Загрузить файл</v-btn
              >
              <v-btn v-if="parserType === PARSER_TYPE.IKEA" color="info" @click="startParser">Начать обновление</v-btn>
            </template>
          </v-flex>
          <v-flex xs12>
            <v-divider class="my-2"></v-divider>
          </v-flex>
          <v-flex v-if="processes.length > 0" xs12 class="grey lighten-3 pa-3">
            <div v-for="(process, index) in processes" :key="index">{{ process }}</div>
          </v-flex>
        </v-layout>
        <v-layout class="mt-3">
          <v-data-table :headers="headers" :items="uploads" class="elevation-1" hide-actions>
            <template v-slot:items="props">
              <td>
                {{ props.item.file_name }}
              </td>
              <td class="text-xs-left">{{ props.item.method }}</td>
              <td class="text-xs-left">
                {{ props.item.updated_at }}
              </td>
              <td class="text-xs-left">
                {{ props.item.is_completed ? 'Завершен' : 'Не завершен' }}
                <template v-if="!props.item.is_completed">
                  {{ props.item.is_changed ? '(изменен)' : '(не изменен)' }}
                </template>
              </td>
              <td class="text-xs-left">
                <a @click="download(props.item.id, props.item.file_name)">Скачать</a>
              </td>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { downloadFile } from '@/utils/downloadFile'

const PARSER_TYPE = {
  XLS: 'xls',
  YML: 'yml',
  IKEA: 'ikea',
  LEROY_MERLIN: 'leroymerlin',
  INMYROOM: 'inmyroom',
}

const PARSER_TYPE_SELECT = [
  { text: 'Форматы', value: '-1', disabled: true },
  { text: 'Загрузка xls', value: PARSER_TYPE.XLS },
  { text: 'Загрузка yml', value: PARSER_TYPE.YML },
  { text: 'Парсеры', value: '-2', disabled: true },
  { text: 'ИКЕА', value: PARSER_TYPE.IKEA },
  { text: 'Leroy Merlin', value: PARSER_TYPE.LEROY_MERLIN, disabled: true },
  { text: 'API', value: '-3', disabled: true },
  { text: 'INMYROOM', value: PARSER_TYPE.INMYROOM, disabled: true },
]

export default {
  data: () => ({
    PARSER_TYPE,
    PARSER_TYPE_SELECT,
    supplier: {},
    loading: false,
    deleteLoading: false,
    dialogDeleteAllProducts: false,
    parserType: PARSER_TYPE.XLS,
    fileExtensions: null,
    intervalId: null,
    parserInProgress: false,
    processes: [],
    uploads: [],
    headers: [
      {
        text: 'Наименование',
        sortable: false,
      },
      {
        text: 'Способ обновления',
        sortable: false,
      },
      {
        text: 'Дата загрузки',
        sortable: false,
      },
      {
        text: 'Статус обновления',
        sortable: false,
      },
      {
        text: '',
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState({ categoriesCombobox: (state) => state.categories.categories }),
  },

  async created() {
    this.supplier.id = this.$route.params.id
    await this.getSupplier()
    await this.checkParserStatus(PARSER_TYPE.IKEA)
    this.fetchUploads()
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
  },

  methods: {
    async getSupplier() {
      const response = await this.axios.get(`/api/admin/suppliers/${this.supplier.id}`)
      this.supplier = response.data.data
    },

    async deleteAllProducts() {
      this.deleteLoading = true
      await this.axios.delete(`/api/admin/suppliers/${this.supplier.id}/products/all`)
      this.deleteLoading = false
      this.dialogDeleteAllProducts = false
    },

    openFileDialog() {
      if (this.parserType === PARSER_TYPE.XLS) {
        this.fileExtensions = '.xls,.xlsx'
      }
      if (this.parserType === PARSER_TYPE.YML) {
        this.fileExtensions = '.yml'
      }
      this.$nextTick(() => {
        this.$refs.file.click()
      })
    },

    async uploadFile(event) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', event.target.files[0], event.target.files[0].name)
      await this.axios.post(`/api/admin/suppliers/${this.supplier.id}/uploads/xls`, formData)
      await this.fetchUploads()
      this.loading = false
    },

    async startParser() {
      this.parserInProgress = true
      await this.axios.post(`/api/admin/suppliers/${this.supplier.id}/parser/${this.parserType}/start`)
      this.intervalId = setInterval(() => {
        this.getParserStatus(this.parserType)
      }, 1000)
    },

    async stopParser() {
      await this.axios.post(`/api/admin/suppliers/${this.supplier.id}/parser/ikea/stop`)
      clearInterval(this.intervalId)
      this.parserInProgress = false
    },

    async clearParser() {
      this.parserInProgress = true
      await this.axios.post(`/api/admin/suppliers/${this.supplier.id}/parser/${this.parserType}/clear`)
      this.intervalId = setInterval(() => {
        this.getParserStatus(this.parserType)
      }, 1000)
    },

    async getParserStatus(parserType) {
      const response = await this.axios.get(`/api/admin/suppliers/${this.supplier.id}/parser/${parserType}/status`)
      this.processes = response.data.data.processes
      if (response.data.data.is_completed) {
        clearInterval(this.intervalId)
        this.parserInProgress = false
      }
    },

    async checkParserStatus(parserType) {
      const response = await this.axios.get(`/api/admin/suppliers/${this.supplier.id}/parser/${parserType}/status`)
      if (!response.data.data.is_completed) {
        this.parserInProgress = true
        this.parserType = parserType
        this.intervalId = setInterval(() => {
          this.getParserStatus(parserType)
        }, 1000)
      }
    },

    async fetchUploads() {
      const response = await this.axios.get(`/api/admin/suppliers/${this.supplier.id}/uploads`)
      this.uploads = response.data.data
    },

    async download(uploadId, name) {
      downloadFile(`/api/admin/suppliers/${this.supplier.id}/uploads/${uploadId}/file`, name)
    },
  },
}
</script>
